.page-not-found {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 31rem;
  padding: 2rem 0 2rem 0;
  text-align: center;
}

.page-not-found-code {
  font-size: 5rem;
}
