* {
	margin: 0;
	padding: 0;
}

body {
	font-family: Karla, sans-serif;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5;
	color: #495e57;
}

header {
	border-bottom: 1rem solid #495e57;
}

a {
	text-decoration: none;
	color: #495e57;
}

h1,
h2 {
	line-height: 1;
	font-family: "Markazi Text";
	font-weight: normal;
}

h1 {
	font-size: 3.5rem;
}

h2 {
	font-size: 2.5rem;
}

form {
	max-width: 28.125rem;
	padding: 3rem 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

label {
	cursor: pointer;
}

input,
select {
	height: 2.5rem;
	padding-inline-end: 1rem;
	padding-inline-start: 1rem;
	outline: none;
	border: 0.0625rem solid #e0e0e0;
	border-radius: 0.375rem;
	background: #ffffff;
	color: #495e57;
	cursor: pointer;
	transition: border-color 0.3s;
}

input:focus,
select:focus {
	border-width: 0.125rem;
	border-color: #495e57;
}

label {
	font-weight: bold;
}

button {
	border: none;
	cursor: pointer;
}

img {
	max-width: 100%;
}

main > section {
	margin-bottom: 5rem;
}

.container {
	max-width: 61.625rem;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.grid {
	display: grid;
}

.button-primary {
	padding: 0.5rem 1rem;
	border-radius: 2rem;
	background-color: #f4ce14;
	color: #495e57;
	font-weight: bold;
	border: 0.1875rem solid #f4ce14;
	transition: background, border, color 0.5s;
	text-align: center;
	align-content: space-evenly;
}

.button-primary:hover,
.button-primary:focus {
	background-color: #495e57;
	border-color: #495e57;
	color: #ffffff;
}
