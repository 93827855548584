.bookings h2 {
  padding: 2rem 0 2rem 0;
  background-color: #495E57;
  color: #FFFFFF;
  text-align: center;
}

.bookings form {
  margin: 0 auto 1.875rem auto;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

.form-field p {
  font-size: 0.9375rem;
  color: red;
}

.bookings button:disabled,
.bookings button[disabled] {
  background-color: #EDEFEE;
  border-color: #EDEFEE;
  color: #ADAFAE;
  cursor: not-allowed;
}

.confirm-booking {
  padding: 0.7rem 0;
}

.confirmed-booking {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 31rem;
  padding: 2rem 0 2rem 0;
  text-align: center;
}
