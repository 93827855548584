.testimonials {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #495E57;
}

.testimonials .container {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  gap: 1.25rem;
}

.testimonials h2 {
  grid-column: 1 / 5;
  grid-row: 1;
  margin-bottom: 2rem;
  color: #FFFFFF;
}

/*-- Media queries ------------------------------------------------------*/

@media (max-width: 949px) {
.testimonials .container {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
}

.testimonials h2 {
  grid-column: 1 / 4;
}
}

@media (max-width: 711px) {
.testimonials .container {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
}

.testimonials h2 {
  grid-column: 1 / 3;
}
}

@media (max-width: 576px) {
.testimonials .container {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
}

.testimonials h2 {
  grid-column: 1 / 2;
}
}
